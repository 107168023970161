import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, vModelText as _vModelText, mergeProps as _mergeProps, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = { class: "text-ellipsis" }
const _hoisted_3 = { class: "vz-async-select__container" }
const _hoisted_4 = {
  key: 0,
  class: "vz-async-select__container-value"
}
const _hoisted_5 = ["placeholder", "disabled", "aria-label"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "my-2 pa-2" }
const _hoisted_8 = {
  key: 1,
  class: "vz-async-select__selected d-flex justify-space-between align-center"
}
const _hoisted_9 = { class: "vz-async-select__badge fill-width" }
const _hoisted_10 = { key: 1 }

import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import type { BaseRecords } from '@shared/models';
import { computed, nextTick, type PropType, ref, useSlots, watch } from 'vue';
import { getLastZIndex, scrollToView, uniqueKey } from '@shared/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';
import { DEFAULT_TABLE_PAGE_SIZE } from '@shared/components/tables/constants/data-table.constants';
import type { ErrorMessageRef } from '@shared/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-async-select',
  props: {
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: Array as PropType<Array<any> | any | undefined>, default: undefined },
  multiple: { type: Boolean, default: false },
  hideSelection: { type: Boolean, default: false },
  autoShown: { type: Boolean, default: false },
  removeLastByBackspace: { type: Boolean, default: false },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  debounce: { type: [String, Number], default: 500 },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  callback: { type: Function as PropType<(...arg: any) => Promise<any>>, required: true },
  payload: { type: Object as PropType<Record<string, any> | undefined>, default: undefined },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  itemText: { type: [String, Function] as PropType<undefined | string | ((value: any) => string)>, default: undefined },
  inputKey: { type: String, default: 'search' },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  itemValue: { type: Function as PropType<(value: any) => any | Array<any>>, default: (value: any) => value },
  initial: { type: Object as PropType<BaseRecords<any>>, default: () => ({ page: { size: DEFAULT_TABLE_PAGE_SIZE }, data: null }) },
  items: { type: Array as PropType<BaseRecords<any>['data'] | undefined>, default: undefined },
  default: { type: [Object, Array, String, Number, Boolean] as PropType<any>, default: () => [] },
  customValue: { type: Function as PropType<((value: string) => boolean) | undefined>, default: undefined },
  noResultsText: { type: String, default: 'DATA.NO_DATA_AVAILABLE' },
},
  emits: ['update:model-value', 'update:state', 'remove:item', 'select:item', 'select:custom', 'search'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const t = useTranslator();
const slots = useSlots();
const inputId = uniqueKey(props.label);

const isFocus = ref<boolean>(false);
const isListShown = ref<boolean>(false);
const blurTimeout = ref<ReturnType<typeof setTimeout>>();
const debounceTimeout = ref<ReturnType<typeof setTimeout>>(0);
const infinityRef = ref();
const inputRef = ref<HTMLInputElement | undefined>(undefined);
const searchValue = ref<string | null>(null);
const selectedIndex = ref<number>(0);
const errorMessageRef = ref<ErrorMessageRef>(undefined);

const listContainerRef = ref<HTMLElement | null>(null);
const dropdownStyles = ref<Record<string, string>>({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '200px',
});

const updateDropdownPosition = () => {
  const input = inputRef.value?.parentElement;

  if (!input) {
    return;
  }

  const rect = input.getBoundingClientRect();
  dropdownStyles.value = {
    position: 'absolute',
    top: `${rect.bottom + window.scrollY}px`,
    left: `${rect.left + window.scrollX}px`,
    width: `${rect.width}px`,
    maxHeight: `${Math.max(window.innerHeight - rect.bottom - 32, 96)}px`,
    zIndex: (getLastZIndex() + 100).toString(),
  };
};

const vModal = computed({
  get: (): Array<any> => (props.modelValue ? (Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) : []),
  set: (value: Array<any> | any) => emit('update:model-value', value),
});

const isCustomValid = computed(() => props.customValue?.(searchValue.value || '') || false);

const internalValue = ref<Array<any>>([]);
const defaultItems = computed(() => (props.default ? [...(props.items || []), props.default] : props.items));

const getTitle = (value: any, itemList?: Array<any>) => {
  const item = [...internalValue.value, ...(itemList || [])].find((item) => props.itemValue(item) === value);

  if (typeof item !== 'object' || !props.itemText) {
    return item;
  }

  return props.itemText instanceof Function ? props.itemText(item) : item[props.itemText];
};

const displayText = computed((): string | null => {
  if (props.multiple && (slots['default'] || slots['badge']) && !props.hideSelection) {
    return null;
  }

  return vModal.value.map((value) => getTitle(value, defaultItems.value)).join(', ');
});

const autoCompleteShown = computed((): boolean => isFocus.value && (props.autoShown || !!internalPayload.value));
const isClearable = computed(() => !!vModal.value && (!Array.isArray(vModal.value) || vModal.value.length));

const top = ref<number>(0);
const width = ref<number>(0);
const maxHeight = ref<number>(0);
const internalPayload = ref<Record<string, any> | undefined>(props.payload);

const externalError = computed(() => {
  if (!props.errorMessage) {
    return;
  }

  if (typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});

const debounce = (value: string | null) => {
  clearTimeout(debounceTimeout.value);

  debounceTimeout.value = setTimeout(() => {
    internalPayload.value = value ? { [props.inputKey]: value, ...(props.payload || {}) } : props.payload;
    inputRef.value?.focus();
  }, +props.debounce);
};

const onFocus = (): void => {
  if (blurTimeout.value) {
    clearTimeout(blurTimeout.value);
  }

  setTimeout(() => {
    let element = inputRef.value?.parentElement;

    while (element && !element.scrollTop) {
      element = element.parentElement;
    }

    const container = inputRef.value?.parentElement;
    const { top: boundingTop = 0, height: boundingHeight = 0 } = container?.getBoundingClientRect() || {};
    top.value = boundingHeight + 2 - (element?.scrollTop || 0) + (props.label ? 32 : 0);
    maxHeight.value = Math.max(element?.clientHeight || window.innerHeight - (boundingTop + boundingHeight + 32), 96);
    width.value = container?.getBoundingClientRect().width || 200;
    isFocus.value = true;
  }, 250);
};

const onBlur = (): void => {
  blurTimeout.value = setTimeout(() => {
    searchValue.value = null;
    isFocus.value = false;
  }, 250);
};

const onInput = (): void => debounce(searchValue.value);

const onQuerySearch = (ev: KeyboardEvent): void => {
  switch (ev.key) {
    case 'Backspace':
      if (searchValue.value?.length || !props.removeLastByBackspace) {
        return;
      }

      if (Array.isArray(vModal.value)) {
        emit('update:model-value', vModal.value.slice(0, -1));
      } else {
        emit('update:model-value', null);
      }
      break;
    case 'ArrowDown':
      selectedIndex.value = Math.min(selectedIndex.value + 1, (infinityRef.value?.items?.length || 0) - 1);
      scrollToView(`.vz-async-select__list-item-${selectedIndex.value}`);
      ev.preventDefault();
      break;
    case 'ArrowUp':
      selectedIndex.value = Math.max(selectedIndex.value - 1, 0);
      scrollToView(`.vz-async-select__list-item-${selectedIndex.value}`);
      ev.preventDefault();
      break;
    case 'Enter':
      if (isCustomValid.value) {
        emit('select:custom', searchValue.value);
      } else if (autoCompleteShown.value) {
        const item = (infinityRef.value?.items || [])[Math.max(selectedIndex.value, 0)];
        if (!item) {
          return;
        }

        onSelectFromList(item);
      }

      ev.preventDefault();
      break;
    case 'Escape':
      inputRef.value?.blur();
      ev.preventDefault();
      break;
    default:
      selectedIndex.value = 0;
      scrollToView(`.vz-async-select__list-item-${selectedIndex.value}`);
      break;
  }
};

const onSelectFromList = (item: Record<string, any>): void => {
  searchValue.value = null;
  internalPayload.value = props.payload;

  const value = props.itemValue(item);

  if (!props.multiple) {
    internalValue.value = [item];
    emit('select:item', item);
    emit('update:model-value', value, item);

    return;
  }

  internalValue.value = [...internalValue.value, item];
  const result = (Array.isArray(value) ? value : [value]).reduce((emitValue: Array<any>, currentValue: any) => {
    const isExists = emitValue.map((selectedItem) => JSON.stringify(selectedItem)).includes(JSON.stringify(currentValue));
    emit(isExists ? 'remove:item' : 'select:item', item);

    return isExists ? emitValue.filter((currentItem) => JSON.stringify(currentItem) !== JSON.stringify(currentValue)) : [...emitValue, currentValue];
  }, vModal.value);

  emit('update:model-value', result, item);
};

const onClearSelectedItem = (index: number) => {
  emit('remove:item', vModal.value[index]);
  emit('update:model-value', props.multiple ? (vModal.value as Array<any>).filter((_, bulkIndex) => bulkIndex !== index) : undefined);
};

const isSelected = (item: any): boolean => {
  const value = props.itemValue(item);

  return vModal.value.map((selectedItem) => JSON.stringify(selectedItem)).includes(JSON.stringify(value));
};

watch(
  () => autoCompleteShown.value,
  (isShown) => nextTick(() => (isListShown.value = isShown)),
  { immediate: true }
);

const addEventListeners = () => {
  window.addEventListener('scroll', updateDropdownPosition, true);
  window.addEventListener('resize', updateDropdownPosition);
};

const removeEventListeners = () => {
  window.removeEventListener('scroll', updateDropdownPosition, true);
  window.removeEventListener('resize', updateDropdownPosition);
};

watch(isListShown, (shown) => {
  if (shown) {
    nextTick(() => {
      updateDropdownPosition();
      addEventListeners();
    });
  } else {
    removeEventListeners();
  }
});

__expose({
  errorMessage: computed(() => errorMessageRef.value?.errorMessage),
  resetValidation: () => errorMessageRef.value?.reset(),
});

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_infinity_scroll = _resolveComponent("vz-infinity-scroll")!
  const _component_vz_error_message = _resolveComponent("vz-error-message")!
  const _directive_z_index = _resolveDirective("z-index")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['vz-async-select', { 'vz-async-select--loading': __props.loading, 'vz-async-select--disabled': __props.disabled }, `vz-async-select-${_unref(inputId)}`]),
    "data-errors": errorMessageRef.value?.errorMessage
  }, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t(__props.label)), 1),
    (!__props.modelValue || __props.multiple || !__props.hideSelection || !__props.multiple || !_unref(slots)['default'])
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "prefix"),
            (_ctx.$slots['selected'] && __props.modelValue)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (!__props.multiple)
                    ? _renderSlot(_ctx.$slots, "default", {
                        key: 0,
                        item: internalValue.value[0]
                      })
                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(internalValue.value, (item, index) => {
                        return _renderSlot(_ctx.$slots, "default", {
                          key: index,
                          item: item,
                          index: index
                        })
                      }), 128))
                ]))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("input", _mergeProps(!isFocus.value || __props.disabled ? { value: displayText.value } : {}, {
              ref_key: "inputRef",
              ref: inputRef,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchValue).value = $event)),
              type: "text",
              tabindex: "0",
              placeholder: displayText.value || _ctx.$t(__props.placeholder),
              disabled: __props.disabled,
              "aria-label": _unref(t)('COMPONENT_LABELS.AUTOCOMPLETE_FIELD', { value: __props.ariaLabel || __props.label || __props.placeholder }),
              onKeydown: onQuerySearch,
              onFocus: onFocus,
              onBlur: onBlur,
              onInput: onInput
            }), null, 16, _hoisted_5), [
              [_vShow, !(_ctx.$slots['selected'] && __props.modelValue)],
              [_vModelText, searchValue.value]
            ]),
            (!__props.disabled && __props.clearable && isClearable.value)
              ? (_openBlock(), _createBlock(_component_vz_icon, {
                  key: 1,
                  clickable: "",
                  role: "button",
                  name: "svg:xmark",
                  size: "0.75rem",
                  color: "primary-900",
                  "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLEAR' }),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:model-value', null)))
                }, null, 8, ["aria-label"]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "append")
          ]),
          (isListShown.value)
            ? (_openBlock(), _createBlock(_Teleport, {
                key: 0,
                to: "body"
              }, [
                _withDirectives((_openBlock(), _createElementBlock("div", {
                  ref_key: "listContainerRef",
                  ref: listContainerRef,
                  class: "vz-async-select__list",
                  style: _normalizeStyle(dropdownStyles.value)
                }, [
                  _createElementVNode("div", {
                    class: "vz-async-select__list-container",
                    role: "list",
                    style: _normalizeStyle({ position: 'fixed', width: width.value + 'px' })
                  }, [
                    (isCustomValid.value)
                      ? _renderSlot(_ctx.$slots, "custom", {
                          key: 0,
                          value: searchValue.value
                        }, () => [
                          _createElementVNode("div", null, _toDisplayString(searchValue.value), 1)
                        ])
                      : _createCommentVNode("", true),
                    _createVNode(_component_vz_infinity_scroll, {
                      ref_key: "infinityRef",
                      ref: infinityRef,
                      class: "fill-height",
                      initial: __props.initial,
                      items: defaultItems.value,
                      callback: __props.callback,
                      payload: internalPayload.value,
                      "hide-empty-state": isCustomValid.value,
                      "onUpdate:state": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:state', $event)))
                    }, {
                      "no-data": _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(__props.noResultsText)), 1)
                      ]),
                      default: _withCtx(({ data }) => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: _normalizeClass([
                    'vz-async-select__list-item',
                    `vz-async-select__list-item-${index}`,
                    {
                      'vz-async-select__list-item--active': selectedIndex.value === index,
                      'vz-async-select__list-item--selected': isSelected(item),
                    },
                  ]),
                            onClick: ($event: any) => (onSelectFromList(item))
                          }, [
                            _createElementVNode("div", _hoisted_7, [
                              _renderSlot(_ctx.$slots, "default", {
                                item: item,
                                isSelected: isSelected(item)
                              }, () => [
                                _createElementVNode("div", null, _toDisplayString(getTitle(item)), 1)
                              ])
                            ])
                          ], 10, _hoisted_6))
                        }), 128))
                      ]),
                      _: 3
                    }, 8, ["initial", "items", "callback", "payload", "hide-empty-state"])
                  ], 4)
                ], 4)), [
                  [_directive_z_index]
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (__props.multiple && !__props.hideSelection && vModal.value?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vModal.value, (item, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (_ctx.$slots['badge'])
                  ? _renderSlot(_ctx.$slots, "badge", {
                      key: 0,
                      item: item,
                      clearable: __props.clearable,
                      onClear: () => onClearSelectedItem(index)
                    })
                  : _renderSlot(_ctx.$slots, "default", {
                      key: 1,
                      item: item,
                      index: index,
                      clearable: __props.clearable,
                      onClear: () => onClearSelectedItem(index)
                    })
              ], 64))
            }), 128))
          ]),
          (!__props.disabled && __props.clearable && isClearable.value && !__props.multiple)
            ? (_openBlock(), _createBlock(_component_vz_icon, {
                key: 0,
                class: "me-2",
                clickable: "",
                role: "button",
                name: "svg:xmark",
                size: "0.75rem",
                color: "primary-900",
                "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLEAR' }),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:model-value', null)))
              }, null, 8, ["aria-label"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!__props.hideDetails)
      ? (_openBlock(), _createBlock(_component_vz_error_message, {
          key: 2,
          ref_key: "errorMessageRef",
          ref: errorMessageRef,
          value: __props.modelValue,
          name: __props.name || __props.label,
          rules: __props.rules,
          errors: __props.errorMessage
        }, {
          default: _withCtx(() => [
            (_ctx.$slots['error-message'])
              ? _renderSlot(_ctx.$slots, "error-message", { key: 0 })
              : (externalError.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t(externalError.value)), 1))
                : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["value", "name", "rules", "errors"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})