import type { AuthActionEnum } from '@/store/auth/constants';
import { ASSIGNED_MODULES, ASSIGNED_USER_ID } from '@/store/auth/auth.constants';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';

export const authAction = (action: keyof typeof AuthActionEnum, name?: string): boolean => {
  const route = useRoute();
  const { [ASSIGNED_MODULES]: modules, [ASSIGNED_USER_ID]: userId } = storeToRefs(useAuthStore());

  if (!userId.value) {
    return true;
  }

  const module = modules.value?.[name || (route.name as string)];

  return module?.includes(action) || false;
};
