import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "vz-collapse__activator-button" }
const _hoisted_2 = {
  key: 2,
  class: "vz-collapse__actions d-flex gap-1"
}

import type { Class, SizeUnit } from '@shared/types';
import { computed, nextTick, onMounted, onUnmounted, onUpdated, type PropType, ref, watch } from 'vue';
import { useFormValidator } from '@shared/components/fields/helpers';
import VzPopover from '@shared/components/menus/vz-popover.vue';
import { useRoute } from 'vue-router';
import { routeTo } from '@shared/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-collapse',
  props: {
  active: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  value: { type: Boolean, default: false },
  hideIcon: { type: Boolean, default: false },
  hideHeaderOnExpand: { type: Boolean, default: false },
  actionOnExpandOnly: { type: Boolean, default: false },
  class: { type: [Object, Array, String] as PropType<Class>, default: () => [] },
  y: { type: String as PropType<SizeUnit>, default: '0.75rem' },
  x: { type: String as PropType<SizeUnit>, default: '0.75rem' },
  routeQuery: { type: Object as PropType<{ key: string; value: string; autoClose?: boolean } | undefined>, default: undefined },
},
  emits: ['update:value', 'click:header'],
  setup(__props, { emit: __emit }) {

const route = useRoute();

const props = __props;

const emit = __emit;

const contentClass = computed(() => (Array.isArray(props.class) ? props.class : [props.class]));

const value = ref(!props.value && !props.disabled);
const vModel = computed({
  get: () => value.value,
  set: (val) => {
    value.value = val;
    emit('update:value', val);
  },
});

const isInitial = ref(true);
const containerRef = ref<Element | undefined>(undefined);
const dynamicRef = ref<Element | undefined>(undefined);
const collapsedRef = ref<Element | undefined>(undefined);
const contentHeight = ref(0);
const collapsedHeight = ref(0);
const isCollapsed = computed(() => vModel.value && !props.disabled);

const validate = (isSilent: boolean = true) => useFormValidator(dynamicRef, isSilent)();

const onCollapse = async (ev?: Event): Promise<void> => {
  ev?.preventDefault();

  const isValid = validate(false);

  if (!isValid && !isCollapsed.value) {
    return;
  }

  vModel.value = !vModel.value;

  if (props.routeQuery) {
    if (!vModel.value) {
      routeTo({ query: { [props.routeQuery.key]: props.routeQuery.value } });
    } else if (props.routeQuery.autoClose || route.query[props.routeQuery.key] === props.routeQuery.value) {
      routeTo({ query: { [props.routeQuery.key]: null } });
    }
  }

  setTimeout(() => {
    containerRef.value?.parentElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, 200);
};

const onUpdate = () => {
  if (!containerRef.value || !dynamicRef.value) {
    return;
  }

  collapsedHeight.value = isCollapsed.value ? collapsedRef.value?.scrollHeight || 0 : 0;
  contentHeight.value = isCollapsed.value ? 0 : dynamicRef.value.scrollHeight;
};

onUpdated(onUpdate);

onMounted(() => {
  if (!validate()) {
    vModel.value = false;
  }

  nextTick(() => {
    isInitial.value = false;
  });

  if (dynamicRef.value) {
    const observer = new MutationObserver(() => {
      for (const time of [100, 200, 300, 400, 500]) {
        setTimeout(onUpdate, time);
      }
    });

    observer.observe(dynamicRef.value, { childList: true, subtree: true });

    onUnmounted(() => {
      observer.disconnect();
    });
  }
});

watch(() => isCollapsed.value, onUpdate, { immediate: true });

watch(
  () => props.value,
  (value) => {
    if (value === vModel.value) {
      onCollapse();
    }
  },
  { immediate: true }
);

watch(
  () => route.query,
  (query) => {
    if (!props.routeQuery) {
      return;
    }

    const value = query[props.routeQuery.key];

    if (value === props.routeQuery.value) {
      vModel.value = false;
    } else if (props.routeQuery?.autoClose) {
      vModel.value = true;
    }
  },
  { immediate: true, deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!

  return (!__props.active)
    ? _renderSlot(_ctx.$slots, "default", { key: 0 })
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        ref_key: "containerRef",
        ref: containerRef,
        class: _normalizeClass(['vz-collapse', { 'vz-collapse--initial': isInitial.value, 'vz-collapse--collapsed': isCollapsed.value }]),
        style: _normalizeStyle({ '--y-position': __props.y, '--x-position': __props.x })
      }, [
        _createElementVNode("div", _hoisted_1, [
          (!__props.actionOnExpandOnly || !isCollapsed.value)
            ? _renderSlot(_ctx.$slots, "prepend", { key: 0 })
            : _createCommentVNode("", true),
          (_ctx.$slots['menu'] && (!__props.actionOnExpandOnly || !isCollapsed.value))
            ? (_openBlock(), _createBlock(VzPopover, { key: 1 }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "menu")
                ]),
                _: 3
              }))
            : _createCommentVNode("", true),
          (!__props.hideIcon && _ctx.$slots['default'])
            ? (_openBlock(), _createBlock(_component_vz_button, {
                key: 2,
                type: "flat",
                "icon-size": "1rem",
                color: __props.disabled ? 'mono-400' : 'primary-900',
                "icon-name": isCollapsed.value ? 'svg:arrow-down' : 'svg:arrow-up',
                onClick: _withModifiers(onCollapse, ["stop"])
              }, null, 8, ["color", "icon-name"]))
            : _createCommentVNode("", true)
        ]),
        (isCollapsed.value || !__props.hideHeaderOnExpand)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "vz-collapse__header",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click:header')))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass({ 'd-flex flex-wrap': _ctx.$slots['actions'] })
              }, [
                (_ctx.$slots['collapse-header'] && isCollapsed.value)
                  ? _renderSlot(_ctx.$slots, "collapse-header", {
                      key: 0,
                      isCollapsed: isCollapsed.value,
                      toggle: onCollapse,
                      disabled: !__props.active || __props.disabled
                    })
                  : _renderSlot(_ctx.$slots, "header", {
                      key: 1,
                      isCollapsed: isCollapsed.value,
                      toggle: onCollapse,
                      disabled: !__props.active || __props.disabled
                    }),
                (_ctx.$slots['actions'] && (!__props.actionOnExpandOnly || !isCollapsed.value))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _renderSlot(_ctx.$slots, "actions")
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots['default'])
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              ref_key: "dynamicRef",
              ref: dynamicRef,
              class: _normalizeClass(['vz-collapse__container', ...contentClass.value]),
              style: _normalizeStyle({ height: `${contentHeight.value}px` })
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 6))
          : _createCommentVNode("", true)
      ], 6))
}
}

})