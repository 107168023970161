import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-body-2"
}
const _hoisted_2 = { class: "vz-calendar__header d-flex justify-space-between align-center ma-2" }
const _hoisted_3 = { class: "d-flex mx-2 gap-2 text-title-1" }
const _hoisted_4 = { class: "vz-calendar__weekdays text-body-2" }
const _hoisted_5 = { class: "vz-calendar__container" }
const _hoisted_6 = { class: "text-title-1" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 1 }

import type { CalendarEvent } from '@/views/calendar/types';
import type { MenuItem } from '@shared/components/menus/models/menu-item';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import { computed, type PropType, ref, watch } from 'vue';
import Calendar from '@/shared/services/calendar-service/calendar.service';
import { useTranslator } from '@/plugins/i18n/helpers';
import dayjs from 'dayjs';
import { useValidator } from '@shared/components/fields/helpers';
import { formattedDate } from '@/views/calendar/helpers';
import { LayoutEnum } from '@shared/directives/layout.enum';
import VzCalendarDay from '@/shared/components/calendar/components/vz-calendar-day.vue';
import VzPopover from '@shared/components/menus/vz-popover.vue';
import { GlobalVariables } from '@shared/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-calendar',
  props: {
  name: { type: String as PropType<string | undefined>, default: undefined },
  viewDate: { type: Object as PropType<Date | undefined | null>, default: undefined },
  modelValue: { type: Object as PropType<Date | undefined | null>, required: true },
  label: { type: String, default: '' },
  minDate: { type: Number, default: undefined }, // timestamp
  maxDate: { type: Number, default: undefined }, // timestamp
  events: { type: Object as PropType<Record<string, Array<CalendarEvent>>>, default: () => ({}) }, // events
  idKey: { type: String, default: '_id' }, // event id key name
  titleKey: { type: String, default: 'title' }, // event title key name
  dateFromKey: { type: String, default: 'dateFrom' }, // event start date key name
  dateToKey: { type: String, default: 'dateTo' }, // event end date key name
  loading: { type: Boolean, default: false },
  extendable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
  rules: { type: Object as PropType<ValidatorFieldRules>, default: () => ({}) },
  errorMessage: { type: String as PropType<string | null>, default: null },
  dayBorder: { type: Boolean, default: true },
  dayActions: { type: Array as PropType<Array<MenuItem> | undefined>, default: undefined },
  dotMark: { type: Boolean, default: false },
},
  emits: ['update:events', 'update:view', 'update:model-value'],
  setup(__props, { emit: __emit }) {

const t = useTranslator();

const props = __props;

const emit = __emit;
const localDate = ref<number>((props.modelValue ? new Date(props.modelValue) : new Date()).valueOf());
const layout = ref<LayoutEnum>(LayoutEnum.xl);

const showOnlyIndicationForEvent = computed(() => [LayoutEnum.xs, LayoutEnum.sm, LayoutEnum.md].includes(layout.value) || props.dotMark);

const calendar = new Calendar();
const actions = computed(() => props.dayActions?.filter(({ hidden }) => !hidden) || []);

const vModel = computed({
  get: (): Date => {
    if (props.modelValue) {
      return new Date(props.modelValue);
    } else if (props.minDate) {
      return new Date(props.minDate);
    }

    return new Date();
  },
  set: (value) => {
    localDate.value = value.valueOf();

    emit('update:model-value', value.valueOf());
  },
});

const today = computed(() => new Date(vModel.value.valueOf()).setHours(0, 0, 0, 0).valueOf());

const monthDisplay = computed(() => dayjs().month(new Date(localDate.value).getMonth()).format('MMMM'));
const yearDisplay = computed(() => dayjs().year(new Date(localDate.value).getFullYear()).format('YYYY'));
const currentValue = computed(() => localDate.value.valueOf());
const currentYear = computed(() => new Date(localDate.value).getFullYear());
const currentMonth = computed(() => new Date(localDate.value).getMonth());
const calendarMatrix = computed(() => calendar.matrix(currentYear.value, currentMonth.value));
const currentDayEvents = computed(() => props.events[dayjs(currentValue.value).format('YYYY-MM-DD')] || []);

const getEventMap = (date: string): Array<CalendarEvent> => {
  return (
    props.events[date]?.map((event) => ({
      ...event,
      tooltip: `${event.fromTime} - ${event.toTime} ${event.title}`,
    })) || []
  );
};

const onSelect = (date: Date) => {
  if (!date || props.disabled) {
    return;
  }

  vModel.value = date;
};

const onNext = () => {
  if (props.disabled) {
    return;
  }

  const date = new Date(localDate.value);
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month === 11) {
    date.setFullYear(year + 1);
    date.setMonth(0);
  } else {
    date.setMonth(month + 1);
  }

  localDate.value = date.valueOf();
  emit('update:view', date);
};

const onBack = () => {
  if (props.disabled) {
    return;
  }

  const date = new Date(localDate.value);
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month === 0) {
    date.setFullYear(year - 1);
    date.setMonth(11);
  } else {
    date.setMonth(month - 1);
  }

  localDate.value = date.valueOf();
  emit('update:view', date);
};

watch(
  () => [currentDayEvents.value],
  () => emit('update:events', currentDayEvents.value),
  { immediate: true }
);

const { validateMessage, isTouched } = useValidator(
  computed(() => vModel.value.valueOf()),
  computed(() => props.rules),
  props.name || props.label
);

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_badge = _resolveComponent("vz-badge")!
  const _component_vz_divider = _resolveComponent("vz-divider")!
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_layout = _resolveDirective("layout")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vz-calendar", { 'vz-calendar--loading': __props.loading, 'vz-calendar--disabled': __props.disabled }])
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.$t(__props.label)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vz_icon, {
        clickable: "",
        name: "svg:previous",
        size: "1.25rem",
        "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.PREVIOUS_MONTH' }),
        onClick: onBack
      }, null, 8, ["aria-label"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(monthDisplay.value), 1),
        _createElementVNode("span", null, _toDisplayString(yearDisplay.value), 1)
      ]),
      _createVNode(_component_vz_icon, {
        clickable: "",
        role: "button",
        name: "svg:arrow-right",
        size: "1.25rem",
        "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.NEXT_MONTH' }),
        onClick: onNext
      }, null, 8, ["aria-label"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(calendar).weekdays, (calendarDay, index) => {
        return (_openBlock(), _createElementBlock("div", { key: calendarDay }, _toDisplayString(_unref(dayjs)().day(index).format('ddd')), 1))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(calendarMatrix.value, ({ date, day, text, isToday, isDifferentMonth }, index) => {
        return (_openBlock(), _createBlock(VzPopover, {
          key: index,
          hold: "",
          "close-timeout": 3000,
          disabled: !actions.value?.length || isDifferentMonth,
          "aria-label": date ? _unref(dayjs)(date).format(_unref(GlobalVariables).FULL_DATE) : undefined,
          onClick: ($event: any) => (onSelect(date)),
          onDblclick: ($event: any) => (actions.value?.[0].click(date.valueOf()))
        }, {
          activator: _withCtx(() => [
            _createVNode(VzCalendarDay, {
              class: _normalizeClass({
              'vz-calendar__day': !!date,
              'vz-calendar__day--outline': __props.dayBorder,
              'vz-calendar__day--today': isToday,
              'vz-calendar__day--active': today.value === date.valueOf(),
              // 'vz-calendar__day--mark': !!events[text]?.length,
              'vz-calendar__day--disabled':
                __props.disabled || (date && __props.minDate && date.valueOf() < __props.minDate) || (date && __props.maxDate && date.valueOf() > __props.maxDate),
              'c-mono-400': isDifferentMonth,
            }),
              extendable: __props.extendable,
              day: day || 0
            }, {
              default: _withCtx(() => [
                (showOnlyIndicationForEvent.value)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(getEventMap(text), (event, eventIndex) => {
                      return _withDirectives((_openBlock(), _createElementBlock("div", {
                        key: eventIndex,
                        class: "vz-calendar__day-mark",
                        style: _normalizeStyle({
                  color: event.color,
                  width: event.eventPercentage + '%',
                  marginInlineStart: event.startPercentage + '%',
                  minWidth: Math.max(event.eventPercentage || 5, 5) + '%',
                })
                      }, [
                        _createTextVNode(_toDisplayString(showOnlyIndicationForEvent.value ? '-' : event.title), 1)
                      ], 4)), [
                        [_directive_tooltip, { isAlwaysOn: true, text: event.tooltip }]
                      ])
                    }), 128))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.events[text], ({ title, fromTime, toTime, color }, eventIndex) => {
                      return _withDirectives((_openBlock(), _createBlock(_component_vz_badge, {
                        key: eventIndex,
                        class: "font-size-12",
                        prefix: fromTime,
                        text: title,
                        color: color
                      }, null, 8, ["prefix", "text", "color"])), [
                        [_directive_tooltip, { text: `${[fromTime, toTime].join(' - ')} ${title}` }]
                      ])
                    }), 128))
              ]),
              _: 2
            }, 1032, ["class", "extendable", "day"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(formattedDate)(date.valueOf())), 1),
            _createVNode(_component_vz_divider, { class: "my-1" }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(actions.value, ({ text: actionText, icon, click: onLinkClick }, actionIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: actionIndex,
                class: "d-flex align-center flex-wrap gap-2 my-1 link text-body-2",
                onClick: ($event: any) => (onLinkClick(date.valueOf()))
              }, [
                icon
                  ? (_openBlock(), _createBlock(_component_vz_icon, {
                      key: 0,
                      name: icon?.name,
                      size: icon?.size || '1.25rem'
                    }, null, 8, ["name", "size"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t(actionText)), 1)
              ], 8, _hoisted_7))
            }), 128))
          ]),
          _: 2
        }, 1032, ["disabled", "aria-label", "onClick", "onDblclick"]))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['vz-calendar__error', { 'vz-calendar__error--hidden': __props.hideDetails }])
    }, [
      (_unref(validateMessage))
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass({ 'vz-datepicker__error-internal': !_unref(isTouched) })
          }, _toDisplayString(_ctx.$t(_unref(validateMessage))), 3))
        : (__props.errorMessage)
          ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t(__props.errorMessage)), 1))
          : _createCommentVNode("", true)
    ], 2)
  ], 2)), [
    [_directive_layout, (value) => (layout.value = value)]
  ])
}
}

})