import { AvailableLanguages } from './available-languages.enum';
import { createI18n } from 'vue-i18n';

import '@/plugins/dayjs-init';

import enLang from '@shared/locale/en.json';
import enShared from '@shared/locale/en-shared.json';

import heLang from '@shared/locale/he.json';
import heShared from '@shared/locale/he-shared.json';

import arLang from '@shared/locale/ar.json';
import arShared from '@shared/locale/ar-shared.json';

import ruLang from '@shared/locale/ru.json';
import ruShared from '@shared/locale/ru-shared.json';

import 'dayjs/locale/he';
import 'dayjs/locale/en';
import 'dayjs/locale/ar';
import 'dayjs/locale/ru';

const en = { ...enShared, ...enLang };
const he = { ...heShared, ...heLang };
const ar = { ...arShared, ...arLang };
const ru = { ...ruShared, ...ruLang };

const i18n = createI18n({
  locale: AvailableLanguages.en,
  fallbackLocale: AvailableLanguages.en,
  globalInjection: true,
  silentTranslationWarn: true,
  messages: { en, he, ar, ru },
});

export default i18n;
