import { defineStore } from 'pinia';
import { StoreNamespace } from '@/store/store-namespace';
import { container } from 'tsyringe';
import AuthService from '@/store/auth/auth.service';
import {
  ASSIGNED,
  CHANGE_PASSWORD,
  COMPLETE_SIGN_UP,
  FORGOT_PASSWORD,
  ASSIGNED_MODULES,
  ASSIGNED_USER_ID,
  ASSIGNEE_LIST,
  AUTH_MODULES,
  GET_AUTH_SESSION,
  CURRENCY,
  USER_EMAIL,
  USER_PHONE,
  IMPERSONATE_TO,
  IS_ADMIN,
  IS_IMPERSONATE_MODE,
  IS_LOGGED_IN,
  IS_PARTIAL_SIGN_UP,
  LANGUAGE,
  LOAD_LOCAL_AUTH_STATE,
  METHOD_OTP_VALIDATE,
  SAVE_TOKEN,
  SESSION,
  SET_ASSIGNEE,
  SET_LANGUAGE,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  TERMS_OF_SERVICE_SIGN_AT,
  VERIFICATION_EXPIRED_AT,
  LANGUAGES,
  GOOGLE_AUTHORIZATION,
} from '@/store/auth/auth.constants';
import authLocalStore from '@/store/auth/helpers/auth-local-store';
import LocalStorageService from '@shared/services/local-storage.service';
import SocketClientService from '@shared/services/socket-service/socket-client.service';
import { computed, ref, watch } from 'vue';
import type {
  AuthForgotPasswordReq,
  AuthRecoverPasswordReq,
  AuthUnsecuredSignIn,
  AuthUnsecuredSignUp,
  CompleteSignUpReq,
  GetAuthSessionRes,
  MethodOtpValidateReq,
} from '@/store/auth/auth.type';
import { locale } from '@shared/locale';
import type { UserAssignee } from '@/views/job/types/job-manager-assignee.type';
import CryptService from '@shared/services/crypt.service';
import { firebaseMessaging } from '@shared/helpers';
import { routeTo } from '@shared/composables';
import Modules from '@/router/constants/modules';
import { handleLanguageChange } from '@/plugins/i18n/helpers';
import { OPEN_LOGIN_MODAL } from '@/views/lobby/constants/lobby-events';
import { LoginModalEnum } from '@/views/lobby/modals/login-modal/login-modal.enum';
import { emitter } from '@/main';
import { AuthEnum } from '@/store/auth/auth.enum';

const { DEFAULT_LANGUAGE } = locale;
const authService = container.resolve(AuthService);

export type AuthState = {
  [SESSION]: GetAuthSessionRes;
  [ASSIGNED]: UserAssignee | null;
  [VERIFICATION_EXPIRED_AT]?: number | null;
};

export const useAuthStore = defineStore(StoreNamespace.AUTH_MODULE, () => {
  const state = ref<Partial<AuthState>>(authLocalStore.load());

  const setLanguage = (languageCode?: string): void => {
    const language = languageCode || DEFAULT_LANGUAGE;

    state.value[SESSION] = { ...(state.value?.[SESSION] || {}), language } as GetAuthSessionRes;
    LocalStorageService.save(LANGUAGE, language);
  };

  const loadLocalAuthState = (): void => {
    const localState = authLocalStore.load();

    Object.assign(state.value, localState);
  };

  const setAssignee = (payload?: UserAssignee): void => {
    state.value[ASSIGNED] = payload || null;
    authLocalStore.save(state.value);
  };

  const getAuthSession = async (): Promise<void> => {
    const session = await authService[GET_AUTH_SESSION]();

    authLocalStore.save({ [SESSION]: session || null });
    state.value[SESSION] = session || null;
    setLanguage(session?.language);

    if (session?._id && session?.key) {
      SocketClientService.setup({ uid: session._id, key: session.key });
    } else {
      SocketClientService.terminate();
    }
  };

  const impersonateTo = async (userId?: string): Promise<void> => {
    const user = await authService[IMPERSONATE_TO](!state.value[SESSION] || state.value[SESSION]?._id !== userId ? userId : undefined);

    if (user) {
      reload();
    }
  };

  const signUp = async (payload: AuthUnsecuredSignUp): Promise<void> => {
    const { email, password, ...restForm } = payload;

    await authService[SIGN_UP]({ hash: CryptService.encrypt(password, email), email, ...restForm });
    reload();
  };

  const completeSignUp = async (payload: CompleteSignUpReq): Promise<void> => {
    await authService[COMPLETE_SIGN_UP](payload);
    await getAuthSession();
  };

  const signIn = async (payload: AuthUnsecuredSignIn): Promise<void> => {
    const { password, email } = payload;

    await authService[SIGN_IN]({ hash: CryptService.encrypt(password, email), email });
    reload();
  };

  const signOut = async (): Promise<void> => {
    const tokens = [firebaseMessaging.tokenId].filter((value) => value);

    await authService[SIGN_OUT]({ tokens });
    await routeTo({ name: Modules.HOME });

    reload();
  };

  const forgotPassword = async (payload: AuthForgotPasswordReq): Promise<number> => {
    const { enableIn } = await authService[FORGOT_PASSWORD](payload);

    return enableIn;
  };

  const changePassword = async (payload: AuthRecoverPasswordReq): Promise<void> => {
    const { password, email, code } = payload;

    await authService[CHANGE_PASSWORD]({ hash: CryptService.encrypt(password, email), email, code: +code });
    await signIn({ email, password });
  };

  const methodOtpValidate = async ({ forceCall, ...content }: MethodOtpValidateReq & { forceCall?: boolean }): Promise<number | void> => {
    if (forceCall || !state.value[VERIFICATION_EXPIRED_AT] || state.value[VERIFICATION_EXPIRED_AT] < Date.now()) {
      const { expiredAt, enableIn } = await authService[METHOD_OTP_VALIDATE](content);
      state.value[VERIFICATION_EXPIRED_AT] = expiredAt;

      return enableIn;
    }
  };

  const saveToken = async (token: string): Promise<void> => {
    try {
      await authService[SAVE_TOKEN](token);
    } catch (e) {
      console.error(e);
    }
  };

  const googleAuthorization = async (): Promise<void> => {
    const { url } = await authService[SIGN_IN]({ method: AuthEnum.GOOGLE });

    window.location.href = url || '';
  };

  const authModules = computed(() => {
    return state.value[ASSIGNED]?.modules ? Object.keys(state.value[ASSIGNED].modules) : state.value[SESSION]?.modules || [];
  });

  const reload = () => window.location.reload();

  watch(
    () => state.value[SESSION]?.hasSignUpCompleted,
    (hasSignUpCompleted) => {
      if (hasSignUpCompleted === false) {
        emitter.emit(OPEN_LOGIN_MODAL, LoginModalEnum.SIGN_UP);
      }
    },
    { immediate: true }
  );

  watch(
    () => state.value[SESSION]?.language,
    async (newValue, oldValue) => {
      handleLanguageChange(newValue);

      if (oldValue && newValue !== oldValue) {
        await getAuthSession();
        window.location.reload();
      }
    },
    { immediate: true }
  );

  return {
    // getters
    [AUTH_MODULES]: authModules,
    [SESSION]: computed(() => state.value[SESSION]),
    [ASSIGNED]: computed(() => state.value[ASSIGNED]),
    [VERIFICATION_EXPIRED_AT]: computed(() => state.value[VERIFICATION_EXPIRED_AT]),
    [LANGUAGE]: computed(() => state.value[SESSION]?.language || DEFAULT_LANGUAGE),
    [LANGUAGES]: computed(() => state.value[SESSION]?.languages || []),
    [IS_LOGGED_IN]: computed(() => !!state.value[SESSION]?._id),
    [CURRENCY]: computed(() => state.value[SESSION]?.currency),
    [ASSIGNEE_LIST]: computed(() => (state.value[SESSION]?.assigned?.length ? state.value[SESSION].assigned : null)),
    [USER_EMAIL]: computed(() => state.value[SESSION]?.email || ''),
    [USER_PHONE]: computed(() => state.value[SESSION]?.phone || ''),
    [ASSIGNED_MODULES]: computed(() => state.value[ASSIGNED]?.modules),
    [ASSIGNED_USER_ID]: computed(() => state.value[ASSIGNED]?.userId),
    [IS_PARTIAL_SIGN_UP]: computed(() => state.value[SESSION]?.hasSignUpCompleted === false),
    [IS_ADMIN]: computed(() => state.value[SESSION]?.role === 'ADMIN'),
    [IS_IMPERSONATE_MODE]: computed(() => !!state.value[SESSION]?.isImpersonate),
    [TERMS_OF_SERVICE_SIGN_AT]: computed(() => state.value[SESSION]?.termOfServiceSignAt || 0),

    // actions
    [GET_AUTH_SESSION]: getAuthSession,
    [IMPERSONATE_TO]: impersonateTo,
    [SIGN_UP]: signUp,
    [COMPLETE_SIGN_UP]: completeSignUp,
    [SIGN_IN]: signIn,
    [SIGN_OUT]: signOut,
    [FORGOT_PASSWORD]: forgotPassword,
    [CHANGE_PASSWORD]: changePassword,
    [METHOD_OTP_VALIDATE]: methodOtpValidate,
    [SAVE_TOKEN]: saveToken,
    [GOOGLE_AUTHORIZATION]: googleAuthorization,

    // mutations
    [SET_LANGUAGE]: setLanguage,
    [SET_ASSIGNEE]: setAssignee,
    [LOAD_LOCAL_AUTH_STATE]: loadLocalAuthState,
  };
});
