import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import type { ColorsMap } from '@shared/services/css-service/types';
import { computed, type PropType } from 'vue';
import { PostJobStatusEnum } from '@/views/job/views/manager/modules/post/constants/post-job-status.enum';
import { StatusEnum } from '@/constants';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-status-badge',
  props: {
  attention: { type: Boolean, default: undefined },
  status: {
    type: String as PropType<keyof typeof StatusEnum | keyof typeof PostJobStatusEnum | undefined | null>,
    required: true,
  },
  prefix: { type: String as PropType<string | undefined>, default: undefined },
  override: { type: Object as PropType<{ text: string; color?: ColorsMap | string } | undefined>, default: undefined },
},
  setup(__props, { expose: __expose }) {

const { t } = useI18n();

const props = __props;

const isAttention = computed(() => {
  if (props.attention !== undefined) {
    return props.attention;
  }

  switch (props.status) {
    case PostJobStatusEnum.PENDING:
    case StatusEnum.PENDING:
      return true;
    default:
      return false;
  }
});

const bind = computed(() => {
  switch (props.status) {
    case PostJobStatusEnum.UPDATE:
      return {
        text: 'GENERAL.UPDATE',
        color: 'yellow-800',
      };
    case PostJobStatusEnum.PENDING:
    case StatusEnum.PENDING:
      return {
        text: 'STATUS.PENDING',
        color: 'red-600',
      };
    case StatusEnum.REJECTED:
      return {
        text: 'STATUS.REJECTED',
        color: 'red-900',
      };
    case PostJobStatusEnum.CLOSED:
      return {
        text: 'STATUS.CLOSED',
        color: 'green-700',
      };
    case StatusEnum.APPROVED:
      return {
        text: 'STATUS.APPROVED',
        color: 'green-700',
      };
    case StatusEnum.EXPIRED:
      return {
        text: 'STATUS.EXPIRED',
        color: 'yellow-900',
      };
    default:
      return {
        text: 'GENERAL.DRAFT',
        color: 'mono-800',
      };
  }
});

__expose({
  value: computed(() => t(props.override?.text || bind.value.text)),
});

return (_ctx: any,_cache: any) => {
  const _component_vz_badge = _resolveComponent("vz-badge")!

  return (__props.status)
    ? (_openBlock(), _createBlock(_component_vz_badge, _mergeProps({
        key: 0,
        class: "vz-status-badge min-width-80",
        attention: isAttention.value
      }, { ..._ctx.$attrs, ...(__props.override || bind.value) }, { prefix: __props.prefix }), null, 16, ["attention", "prefix"]))
    : _createCommentVNode("", true)
}
}

})