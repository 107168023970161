import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withKeys as _withKeys, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vz-page-wrapper"
}
const _hoisted_2 = { class: "vz-page-wrapper__menu-item pa-0" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["aria-label", "onClick", "onKeydown"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "vz-page-wrapper__menu ps-10"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "vz-page-wrapper__content" }

import type { RouteItem } from '@/router/models/route-item';
import { computed, type PropType, ref } from 'vue';
import { useMenuItems } from '@/router/helpers';
import { useRoute } from 'vue-router';
import { isMobile } from '@shared/helpers';
import { routeTo } from '@shared/composables';
import VzCollapse from '@shared/components/vz-collapse.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-page-wrapper',
  props: {
  modules: { type: Array as PropType<Array<string> | null>, default: null },
  showSideBar: { type: Boolean, default: false },
},
  setup(__props) {

const route = useRoute();

const props = __props;

const isShown = ref<boolean>(!isMobile);

const activeItem = computed(() => items.value.find((item) => item.name === route.name));

const items = useMenuItems({
  routeName: computed(() => route.meta.parentName as string),
  authModules: computed(() => props.modules),
});

const showSideNavbar = computed(() => {
  return (items.value?.length && items.value.length > 1) || props.showSideBar;
});

const onClick = (item: RouteItem) => {
  routeTo({ name: item.name }, { isOverride: true });

  isShown.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!

  return (!_unref(route).meta.hideMenu)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (activeItem.value && showSideNavbar.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "vz-page-wrapper__minimal",
              role: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isShown.value = !isShown.value))
            }, [
              _renderSlot(_ctx.$slots, "header", { isShown: isShown.value }),
              _createElementVNode("div", _hoisted_2, [
                (activeItem.value?.label)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(activeItem.value.label)), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (showSideNavbar.value || _ctx.$slots['menu'])
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(['vz-page-wrapper__menu', { 'vz-page-wrapper__menu--hidden': !isShown.value }])
            }, [
              _renderSlot(_ctx.$slots, "menu"),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(items), (item, index) => {
                return _renderSlot(_ctx.$slots, "item", {
                  key: `${item.name}-${index}`,
                  item: item,
                  onClick: () => onClick(item)
                }, () => [
                  _createVNode(VzCollapse, {
                    value: item.subitems?.some((subItem) => subItem.name === _unref(route).name),
                    y: "1.5rem",
                    x: "1rem"
                  }, _createSlots({
                    header: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(['vz-page-wrapper__menu-item', { 'vz-page-wrapper__menu-item--active': item.name === _unref(route).name }]),
                        tabindex: "0",
                        role: "button",
                        "aria-label": item.label,
                        onClick: ($event: any) => (onClick(item)),
                        onKeydown: _withKeys(($event: any) => (onClick(item)), ["enter"])
                      }, [
                        (item.icon)
                          ? (_openBlock(), _createBlock(_component_vz_icon, {
                              key: 0,
                              size: "1.75rem",
                              name: item.icon
                            }, null, 8, ["name"]))
                          : _createCommentVNode("", true),
                        (item.label)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t(item.label)), 1))
                          : _createCommentVNode("", true)
                      ], 42, _hoisted_4)
                    ]),
                    _: 2
                  }, [
                    (item.subitems?.length)
                      ? {
                          name: "default",
                          fn: _withCtx(() => [
                            (item.subitems)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subitems, (subItem, subIndex) => {
                                    return _renderSlot(_ctx.$slots, "item", {
                                      key: `${subItem.name}-${subIndex}`,
                                      item: subItem,
                                      onClick: () => onClick(subItem)
                                    }, () => [
                                      _createElementVNode("div", {
                                        class: _normalizeClass(['vz-page-wrapper__menu-item', { 'vz-page-wrapper__menu-item--active': subItem.name === _unref(route).name }]),
                                        onClick: ($event: any) => (onClick(subItem))
                                      }, [
                                        (subItem.icon)
                                          ? (_openBlock(), _createBlock(_component_vz_icon, {
                                              key: 0,
                                              size: "1.75rem",
                                              name: subItem.icon
                                            }, null, 8, ["name"]))
                                          : _createCommentVNode("", true),
                                        (subItem.label)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t(subItem.label)), 1))
                                          : _createCommentVNode("", true)
                                      ], 10, _hoisted_7)
                                    ])
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["value"])
                ])
              }), 128))
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]))
    : _renderSlot(_ctx.$slots, "default", { key: 1 })
}
}

})