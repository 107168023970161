<template>
  <slot v-if="!active" />

  <div
    v-else
    ref="containerRef"
    :class="['vz-collapse', { 'vz-collapse--initial': isInitial, 'vz-collapse--collapsed': isCollapsed }]"
    :style="{ '--y-position': y, '--x-position': x }"
  >
    <div class="vz-collapse__activator-button">
      <slot v-if="!actionOnExpandOnly || !isCollapsed" name="prepend" />

      <vz-popover v-if="$slots['menu'] && (!actionOnExpandOnly || !isCollapsed)">
        <slot name="menu" />
      </vz-popover>

      <vz-button
        v-if="!hideIcon && $slots['default']"
        type="flat"
        icon-size="1rem"
        :color="disabled ? 'mono-400' : 'primary-900'"
        :icon-name="isCollapsed ? 'svg:arrow-down' : 'svg:arrow-up'"
        @click.stop="onCollapse"
      />
    </div>

    <div v-if="isCollapsed || !hideHeaderOnExpand" class="vz-collapse__header" @click="$emit('click:header')">
      <div :class="{ 'd-flex flex-wrap': $slots['actions'] }">
        <slot
          v-if="$slots['collapse-header'] && isCollapsed"
          name="collapse-header"
          :is-collapsed="isCollapsed"
          :toggle="onCollapse"
          :disabled="!active || disabled"
        />

        <slot v-else name="header" :is-collapsed="isCollapsed" :toggle="onCollapse" :disabled="!active || disabled" />

        <div v-if="$slots['actions'] && (!actionOnExpandOnly || !isCollapsed)" class="vz-collapse__actions d-flex gap-1">
          <slot name="actions" />
        </div>
      </div>
    </div>

    <div v-if="$slots['default']" ref="dynamicRef" :class="['vz-collapse__container', ...contentClass]" :style="{ height: `${contentHeight}px` }">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Class, SizeUnit } from '@shared/types';
import { computed, nextTick, onMounted, onUnmounted, onUpdated, type PropType, ref, watch } from 'vue';
import { useFormValidator } from '@shared/components/fields/helpers';
import VzPopover from '@shared/components/menus/vz-popover.vue';
import { useRoute } from 'vue-router';
import { routeTo } from '@shared/composables';

const route = useRoute();

const props = defineProps({
  active: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  value: { type: Boolean, default: false },
  hideIcon: { type: Boolean, default: false },
  hideHeaderOnExpand: { type: Boolean, default: false },
  actionOnExpandOnly: { type: Boolean, default: false },
  class: { type: [Object, Array, String] as PropType<Class>, default: () => [] },
  y: { type: String as PropType<SizeUnit>, default: '0.75rem' },
  x: { type: String as PropType<SizeUnit>, default: '0.75rem' },
  routeQuery: { type: Object as PropType<{ key: string; value: string; autoClose?: boolean } | undefined>, default: undefined },
});

const emit = defineEmits(['update:value', 'click:header']);

const contentClass = computed(() => (Array.isArray(props.class) ? props.class : [props.class]));

const value = ref(!props.value && !props.disabled);
const vModel = computed({
  get: () => value.value,
  set: (val) => {
    value.value = val;
    emit('update:value', val);
  },
});

const isInitial = ref(true);
const containerRef = ref<Element | undefined>(undefined);
const dynamicRef = ref<Element | undefined>(undefined);
const collapsedRef = ref<Element | undefined>(undefined);
const contentHeight = ref(0);
const collapsedHeight = ref(0);
const isCollapsed = computed(() => vModel.value && !props.disabled);

const validate = (isSilent: boolean = true) => useFormValidator(dynamicRef, isSilent)();

const onCollapse = async (ev?: Event): Promise<void> => {
  ev?.preventDefault();

  const isValid = validate(false);

  if (!isValid && !isCollapsed.value) {
    return;
  }

  vModel.value = !vModel.value;

  if (props.routeQuery) {
    if (!vModel.value) {
      routeTo({ query: { [props.routeQuery.key]: props.routeQuery.value } });
    } else if (props.routeQuery.autoClose || route.query[props.routeQuery.key] === props.routeQuery.value) {
      routeTo({ query: { [props.routeQuery.key]: null } });
    }
  }

  setTimeout(() => {
    containerRef.value?.parentElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, 200);
};

const onUpdate = () => {
  if (!containerRef.value || !dynamicRef.value) {
    return;
  }

  collapsedHeight.value = isCollapsed.value ? collapsedRef.value?.scrollHeight || 0 : 0;
  contentHeight.value = isCollapsed.value ? 0 : dynamicRef.value.scrollHeight;
};

onUpdated(onUpdate);

onMounted(() => {
  if (!validate()) {
    vModel.value = false;
  }

  nextTick(() => {
    isInitial.value = false;
  });

  if (dynamicRef.value) {
    const observer = new MutationObserver(() => {
      for (const time of [100, 200, 300, 400, 500]) {
        setTimeout(onUpdate, time);
      }
    });

    observer.observe(dynamicRef.value, { childList: true, subtree: true });

    onUnmounted(() => {
      observer.disconnect();
    });
  }
});

watch(() => isCollapsed.value, onUpdate, { immediate: true });

watch(
  () => props.value,
  (value) => {
    if (value === vModel.value) {
      onCollapse();
    }
  },
  { immediate: true }
);

watch(
  () => route.query,
  (query) => {
    if (!props.routeQuery) {
      return;
    }

    const value = query[props.routeQuery.key];

    if (value === props.routeQuery.value) {
      vModel.value = false;
    } else if (props.routeQuery?.autoClose) {
      vModel.value = true;
    }
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss">
.vz-collapse {
  position: relative;
  width: 100%;

  :has([validate='errors']) {
    background-color: red !important;
  }

  &__actions {
    margin-inline-start: auto;
    margin-inline-end: calc(var(--x-position) + 1rem + 4px);

    .vz-collapse--collapsed & {
      @include hover-support {
        transition: opacity 0.3s;
        opacity: 0;
      }
    }
  }

  &__activator-button {
    display: flex;
    position: absolute !important;
    z-index: 1000;
    top: var(--y-position);
    @include inline-end(var(--x-position));
    transform: translateY(-50%);
  }

  &:hover {
    .vz-collapse__actions {
      opacity: 1;
    }
  }

  &:not(&--initial) {
    .vz-collapse__header,
    .vz-collapse__container {
      overflow: hidden;
      transition: height 0.3s;
      max-height: fit-content;
    }
  }
}
</style>
