import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-title-3 text-center text-ellipsis"
}
const _hoisted_2 = {
  key: 1,
  flat: "",
  class: "tab-switcher-navigation"
}
const _hoisted_3 = {
  key: 1,
  class: "tab-switcher-navigation__actions"
}
const _hoisted_4 = ["aria-label", "onClick", "onKeydown"]
const _hoisted_5 = { class: "d-flex align-center" }
const _hoisted_6 = { class: "fill-width text-center" }
const _hoisted_7 = {
  key: 2,
  class: "tab-switcher-navigation__actions"
}

import type { SizeUnit } from '@shared/types';
import type { SwitchTab } from '@/shared/components/content-switcher/tab-switcher.type';
import { computed, nextTick, type PropType, ref, useSlots, watch } from 'vue';
import { routeTo } from '@shared/composables';
import { useFormValidator } from '@shared/components/fields/helpers';
import { isEqual } from 'lodash';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-tab-switcher',
  props: {
  divider: { type: Boolean, default: false },
  alwaysShow: { type: Boolean, default: false },
  tabIndex: { type: Number as PropType<number>, default: 0 },
  center: { type: Boolean, default: false },
  showSeparator: { type: Boolean, default: false },
  hideSingleTab: { type: Boolean, default: false },
  fit: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  sticky: { type: Boolean, default: false },
  minTabWidth: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  title: { type: String as PropType<string | undefined>, default: undefined },
  hideTabs: { type: Boolean, default: false },
  uppercase: { type: Boolean, default: true },
  autofocus: { type: Boolean, default: true },
  tabs: { type: [Number, Array] as PropType<Array<string | number | SwitchTab> | number>, required: true },
  hideNextButton: { type: Boolean, default: false },
  hideBackButton: { type: Boolean, default: false },
  hideTabsOnSplash: { type: Boolean, default: false },
  enableEmptyState: { type: Boolean, default: true },
  hiddenIndexList: { type: Array as PropType<Array<number>>, default: () => [] },
  validationCallback: { type: Function as PropType<(() => void | boolean) | undefined>, default: undefined },
},
  emits: ['update:tab-index'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const slots = useSlots();

const tabsRef = ref<HTMLDivElement | undefined>(undefined);
const contentRef = ref<HTMLDivElement | undefined>(undefined);

const renderTabs = computed((): Array<SwitchTab> => {
  if (Array.isArray(props.tabs)) {
    return props.tabs
      .filter((value) => typeof value !== 'object' || !value.hidden)
      .map((value, index) =>
        typeof value === 'object'
          ? { ...value, name: value.name || (index + 1).toString() }
          : { label: value.toString(), name: (index + 1).toString() }
      );
  }

  return Array.from(Array(+props.tabs).keys()).map((num) => ({ label: (num + 1).toString(), name: (num + 1).toString() }));
});

const activeTabIndex = ref<number>(props.tabIndex);

const vIndex = computed({
  get: () => activeTabIndex.value,
  set: (value: number) => {
    activeTabIndex.value = value;
    emit('update:tab-index', value);

    setCenter();
  },
});

const validate = (isSilent?: boolean): boolean => {
  props.validationCallback?.();

  if (renderTabs.value?.[vIndex.value]?.validate) {
    const isValid = useFormValidator(contentRef, isSilent);

    return isValid();
  }

  return true;
};

const setCenter = () => {
  nextTick(() => {
    tabsRef.value?.querySelector('.tab-switcher-navigation__panel-tab--active')?.scrollIntoView({ inline: 'center' });
  });
};

const isContentExists = computed(
  (): boolean => !!slots['content'] || !!slots['default'] || !!slots[`${tabName.value || vIndex.value + 1}`] || !!slots['splash']
);

const contentClass = computed(() => {
  return ['tab-switcher-content', `tab-switcher-content-${tabName.value}`, { 'tab-switcher-content--splash': slots['splash'] }];
});

const isTabsShown = computed((): boolean => {
  if (props.alwaysShow) {
    return true;
  }

  if (props.hideSingleTab && renderTabs.value.length <= 1) {
    return false;
  }

  return !!renderTabs.value.length && !props.hiddenIndexList.includes(vIndex.value);
});

const isTabsHideOnSplash = computed((): boolean => !!slots['splash'] && props.hideTabsOnSplash);

const tabName = computed(() => renderTabs.value?.[vIndex.value]?.name);
const tabStyle = computed(() => renderTabs.value?.[vIndex.value]?.style);
const totalTabs = computed((): number => renderTabs.value.length);

const isPrevPageEnabled = computed((): boolean => vIndex.value > 0 || !props.autofocus);

const onPrevPage = (): void => {
  if (props.autofocus) {
    onSelect(Math.max(0, vIndex.value - 1));
  }
};

const isNextPageEnabled = computed((): boolean => totalTabs.value - 1 > vIndex.value);

const onNextPage = (): void => {
  onSelect(Math.min(totalTabs.value - 1, vIndex.value + 1));
};

const onSelect = (index: number, isClicked: boolean = false): void => {
  if (!validate() || index < 0) {
    return;
  }

  if (isClicked) {
    renderTabs.value[index]?.onClick?.(index);

    if (renderTabs.value[index].route) {
      routeTo(renderTabs.value[index].route!);
    }
  }

  vIndex.value = index;
};

watch(
  () => renderTabs.value,
  (newValue, oldValue) => {
    nextTick(() => {
      const findNewIndex = newValue.findIndex((tab) => isEqual(tab, oldValue?.[vIndex.value]));

      if (findNewIndex !== -1) {
        onSelect(findNewIndex);
        contentRef.value?.removeAttribute('validate');
      }
    });
  },
  { deep: true }
);

watch(
  () => props.tabIndex,
  (value) => {
    if (value !== undefined && value !== vIndex.value) {
      onSelect(props.tabIndex);
    }
  },
  { immediate: true }
);

__expose({ validate, next: onNextPage, previous: onPrevPage, select: onSelect, index: vIndex, name: tabName });

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['tab-switcher', { 'tab-switcher--with-content': isContentExists.value, 'tab-switcher--flat': __props.flat }])
  }, [
    (!__props.hideTabs)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([
        'tab-switcher-header',
        { 'tab-switcher-header--active': _ctx.$slots['header'] || __props.title, 'sticky-top': __props.sticky, 'border-bottom-regular pb-2': __props.divider && isTabsShown.value },
      ])
        }, [
          (__props.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t(__props.title)), 1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "header", {
            index: vIndex.value,
            tab: tabName.value
          }),
          (isTabsShown.value && !isTabsHideOnSplash.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (!__props.hideBackButton)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(['tab-switcher-navigation__back', { 'tab-switcher-navigation__back--disabled': !isPrevPageEnabled.value }]),
                      role: "button",
                      tabindex: "0",
                      onClick: onPrevPage,
                      onKeydown: _withKeys(onPrevPage, ["enter"])
                    }, [
                      _createVNode(_component_vz_icon, {
                        name: "svg:arrow-left",
                        size: "1rem",
                        "aria-label": _ctx.$t('GENERAL.BACK'),
                        color: isPrevPageEnabled.value ? 'currentColor' : 'mono-400'
                      }, null, 8, ["aria-label", "color"])
                    ], 34))
                  : _createCommentVNode("", true),
                (_ctx.$slots['prepend'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _renderSlot(_ctx.$slots, "prepend", {
                        index: vIndex.value,
                        tab: tabName.value
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  ref_key: "tabsRef",
                  ref: tabsRef,
                  class: _normalizeClass(['tab-switcher-navigation__panel', { 'tab-switcher-navigation__panel--fit': __props.fit, 'text-center': __props.center }])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderTabs.value, ({ label, iconName, color }, renderTabIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: renderTabIndex,
                      class: _normalizeClass([
              'tab-switcher-navigation__panel-tab',
              {
                'tab-switcher-navigation__panel-tab--active': vIndex.value === renderTabIndex,
                'tab-switcher-navigation__panel-tab--mark': vIndex.value === renderTabIndex,
                'text-uppercase': __props.uppercase,
              },
            ]),
                      tabindex: "0",
                      role: "button",
                      "aria-label": _ctx.$t(label.toString()),
                      style: _normalizeStyle({ minWidth: __props.minTabWidth, color: `var(--color-${color})` }),
                      onClick: ($event: any) => (onSelect(renderTabIndex, true)),
                      onKeydown: _withKeys(($event: any) => (onSelect(renderTabIndex, true)), ["enter"])
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        iconName
                          ? (_openBlock(), _createBlock(_component_vz_icon, {
                              key: 0,
                              name: iconName,
                              size: "1.25rem"
                            }, null, 8, ["name"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(label ? _ctx.$t(label.toString()) : null), 1)
                      ])
                    ], 46, _hoisted_4))
                  }), 128))
                ], 2),
                (_ctx.$slots['append'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _renderSlot(_ctx.$slots, "append", { index: vIndex.value })
                    ]))
                  : _createCommentVNode("", true),
                (!__props.hideNextButton)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      class: _normalizeClass(['tab-switcher-navigation__next', { 'tab-switcher-navigation__next--disabled': !isNextPageEnabled.value }]),
                      role: "button",
                      tabindex: "0",
                      onClick: onNextPage,
                      onKeydown: _withKeys(onNextPage, ["enter"])
                    }, [
                      _createVNode(_component_vz_icon, {
                        name: "svg:arrow-right",
                        size: "1rem",
                        "aria-label": _ctx.$t('GENERAL.NEXT'),
                        color: isNextPageEnabled.value ? 'currentColor' : 'mono-400'
                      }, null, 8, ["aria-label", "color"])
                    ], 34))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (isContentExists.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref_key: "contentRef",
          ref: contentRef,
          class: _normalizeClass(contentClass.value),
          style: _normalizeStyle(tabStyle.value)
        }, [
          (_ctx.$slots['splash'])
            ? _renderSlot(_ctx.$slots, "splash", { key: 0 })
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.$slots[tabName.value || vIndex.value + 1])
                  ? _renderSlot(_ctx.$slots, tabName.value || vIndex.value + 1, _normalizeProps(_mergeProps({ key: 0 }, _ctx.$attrs)))
                  : _renderSlot(_ctx.$slots, "default", _mergeProps({
                      key: 1,
                      index: vIndex.value,
                      tab: tabName.value
                    }, _ctx.$attrs))
              ], 64))
        ], 6))
      : _createCommentVNode("", true)
  ], 2))
}
}

})