<template>
  <div class="empty-state d-flex flex-column align-center justify-center fill-height fill-width py-4">
    <vz-svg-href class="empty-state__image fill-width height-p50 px-16" :name="splashImage" />

    <div class="mb-6">
      <vz-error-alert v-if="errors?.errorMessage?.length" class="empty-state__error" :errors="errors" />

      <p v-else :class="`empty-state__message mt-1 font-size-${fontSize} font-weight-${fontWeight} c-${color}`">{{ $t(noDataText) }}</p>
    </div>

    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { ErrorResponse } from '@shared/services/api-service/models';
import type { SplashName } from '@shared/components/svg-href/svg-splash.type';
import type { ColorsMap } from '@shared/services/css-service/types';

const props = defineProps({
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  noDataText: { type: String, default: 'DATA.NO_DATA_AVAILABLE' },
  noDataImage: { type: String as PropType<SplashName>, default: 'no-data' },
  color: { type: String as PropType<ColorsMap>, default: 'mono-700' },
  fontSize: { type: Number as PropType<10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32 | 34 | 36 | 48>, default: 18 },
  fontWeight: { type: Number as PropType<400 | 500 | 600 | 700>, default: 400 },
});

const splashImage = computed((): SplashName => {
  if (props.errors?.errorMessage?.length) {
    return 'server-error';
  }

  return props.noDataImage;
});
</script>
