<template>
  <div class="vz-error-message mt-2" role="alert" :data-errors="internalError">
    <slot :error-message="errorMessage" :is-touched="isTouched">
      <p v-if="errorMessage" :class="{ 'vz-error-message--internal': !isTouched }">{{ $t(errorMessage) }}</p>
    </slot>
  </div>
</template>

<script setup lang="ts">
import type { ErrorResponse, ErrorResponseMessage } from '@shared/services/api-service/models';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import { useValidator } from '@shared/components/fields/helpers';
import { computed, type PropType } from 'vue';

const props = defineProps({
  name: { type: String as PropType<string | undefined>, required: true },
  value: { type: [String, Number, Boolean, Object, Array] as PropType<any>, required: true },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  errors: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
});

const { validateMessage: internalError, isTouched } = useValidator(
  computed(() => props.value),
  computed(() => props.rules),
  props.name
);

const externalErrors = computed((): Array<string> | null => {
  if (!props.errors) {
    return null;
  }

  if (typeof props.errors === 'string') {
    return [props.errors];
  }

  const { errorMessage } = props.errors as ErrorResponse;

  if (!errorMessage) {
    return null;
  }

  return errorMessage.map((error: ErrorResponseMessage) => error.message);
});

const errorMessage = computed(() => {
  if (externalErrors.value?.length) {
    return externalErrors.value[0];
  }

  return internalError.value;
});

const hasErrors = computed(() => !!errorMessage.value);

defineExpose({ errorMessage, hasErrors, reset: () => (isTouched.value = false) });
</script>

<style scoped lang="scss">
.vz-error-message {
  font-size: var(--font-size-16);
  color: var(--color-red-700);
  line-height: var(--line-height-16);

  &--hidden {
    height: 0;
  }

  :not(&--hidden) {
    min-height: 1.25rem;
  }

  &--internal {
    display: none;

    [validate='errors'] & {
      display: initial;
    }
  }
}
</style>
